nav {
  background: #161616;
  width: 100%;
  height: 60px;
  align-items: center;
}
.navbar-div {
  max-height: 60px;
  width: 100%;
}
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
  box-shadow: inset 0 -1px 0 #e5e7eb;
}

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.transparentBlack {
  background-color: rgba(0, 0, 0, 0.2);
}

.slider {
  -webkit-appearance: none;
  width: 90%;
  height: 15px;
  border-radius: 5px;  
  background: #ff00008f;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #ff0000;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ff0000;
  cursor: pointer;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  background: white;
  color: white;
  border-width: 4px;
  border-color: #ff0000;
  background-color: #202020;
  z-index: 50;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: #161616;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 6px;
  margin: 4px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #ffffff;
  background: rgb(255, 0, 0);
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  color:red;
  background: white;
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.team-img {
  width: 100%;
  max-height: 70%; 
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper-off {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
  opacity: 1;
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    height: 100%;
  }
  100% {
    opacity: 0;
    z-index: -1;
    height: 1%;
  }
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #Fff;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

@include keyframes(bounce) {
	0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
	40% {
    @include transform(translateY(-30px));
  }
	60% {
    @include transform(translateY(-15px));
  }
}
.bounce {
  @include animation(bounce 2s infinite);
}

.fixed {
  position: fixed;
  top: 0;
  z-index: 20;
}

.navbar-sticky {
  position: fixed;
  z-index: 20;
}
.timeline-wrap {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.red-glow {
  z-index: 9;
  width: 10px;
  height: 100%;
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
  background-color: rgb(255, 0, 0);
  opacity: 0.8;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.lore-bg {
  background: rgb(40,12,12);
  background: -moz-radial-gradient(circle, rgba(40,12,12,1) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(40,12,12,1) 0%, rgba(0,0,0,1) 100%);
  background: radial-gradient(circle, rgba(40,12,12,1) 0%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#280c0c",endColorstr="#000000",GradientType=1);
}

.lore-bg-2 {
  background: rgb(126,24,37);
background: -moz-radial-gradient(circle, rgba(126,24,37,1) 0%, rgba(0,0,0,1) 100%);
background: -webkit-radial-gradient(circle, rgba(126,24,37,1) 0%, rgba(0,0,0,1) 100%);
background: radial-gradient(circle, rgba(126,24,37,1) 0%, rgba(0,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7e1825",endColorstr="#000000",GradientType=1);
}

.header-bg {
  background: rgb(59,58,58);
background: -moz-radial-gradient(circle, rgba(59,58,58,1) 0%, rgba(0,0,0,1) 100%);
background: -webkit-radial-gradient(circle, rgba(59,58,58,1) 0%, rgba(0,0,0,1) 100%);
background: radial-gradient(circle, rgba(59,58,58,1) 0%, rgba(0,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b3a3a",endColorstr="#000000",GradientType=1);
}
.red-earning-bg {
  background-color: #3f0d12;
  background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);
}
.black-retirement-bg {
  background-color: #000000;
  background-image: linear-gradient(45deg, #000000 0%, #414141 74%);
}
.blue-purple-lie-bg {
  background-color: #4c4177;
  background-image: linear-gradient(315deg, #4c4177 0%, #2a5470 74%);
}
.gray-tie-bg {
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
}
.gray-secret-bg {
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%); 
}
.red-black-bg {
  background-color: #000000;
background-image: linear-gradient(135deg, #000000 0%, #b82e1f 74%);
}
.slow-internet-bg{
  background-color: #000000;
background-image: linear-gradient(315deg, #000000 0%, #b82e1f 74%);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Not-Found {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Not-Found > img {
  max-width: 33%;
}
.Not-Found > p {
  font-family: 'Permanent Marker';
  font-size: 3em;
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mint-soon-button {
  cursor: not-allowed;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-container-shadow {
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.5);
}

.title-text-shadow {
  text-shadow: 2px 2px #000;
}